import { __ } from '~/locale';

export const MSG_ISSUES_ASSIGNED_TO_ME = __('Issues assigned to me');

export const MSG_ISSUES_IVE_CREATED = __("Issues I've created");

export const MSG_MR_ASSIGNED_TO_ME = __('Merge requests assigned to me');

export const MSG_MR_IM_REVIEWER = __("Merge requests that I'm a reviewer");

export const MSG_MR_IVE_CREATED = __("Merge requests I've created");

export const MSG_IN_ALL_GITLAB = __('in all GitLab');

export const MSG_IN_GROUP = __('in group');

export const MSG_IN_PROJECT = __('in project');
